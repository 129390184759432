import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import MiniOverlayModal from 'dpl/common/mini_overlay_modal/components/MiniOverlayModal';
import MediaCarousel from 'dpl/components/MediaCarousel';

export default function MediaGridCarousel({
  selectedMediaIdx,
  mediaFiles,
  onClose
}) {
  const muxRef = useRef(null);
  const [currentFile, setCurrentFile] = useState(
    mediaFiles[selectedMediaIdx] || null
  );

  function setImage(id) {
    const idx = mediaFiles.findIndex(file => file.id === id);
    setCurrentFile(mediaFiles[idx]);
  }

  return (
    <MiniOverlayModal
      className="MediaGridCarouselModal"
      contentClassName="bg-black overflow-hidden"
      onCloseClick={onClose}
      maxWidth="800px"
      scrollableContent
      mobileCompact
    >
      <div className="MediaGridCarousel br4 h-100">
        <div className="w-100 h-100">
          <MediaCarousel
            muxRef={muxRef}
            files={mediaFiles}
            currentFile={currentFile}
            onFileChange={setImage}
            arrowClassName="icon-default bg-white br-100 box-shadow-strong"
            smartImageProps={{
              className:
                'transform-center left-0 right-0 max-h-100 h-100 h-auto center',
              imageOnlyClassName: 'max-h-100 h-auto',
              loadingClass: 'h-100 loading-spinner',
              maxWidth: '800px'
            }}
          />
        </div>
      </div>
    </MiniOverlayModal>
  );
}

MediaGridCarousel.propTypes = {
  selectedMediaIdx: PropTypes.number,
  mediaFiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired
};

MediaGridCarousel.defaultProps = {
  selectedMediaIdx: null
};
