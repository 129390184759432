import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import createFetchData from '../utils/createFetchData';
import generateQueryKey from '../utils/generateQueryKey';

export default function useInfiniteCollectionQuery({
  params,
  prefix,
  url,
  getData = data => data.data,
  ...config
}) {
  const queryKey = generateQueryKey({ params, prefix, url });

  const query = useInfiniteQuery({
    ...(config || {}),

    queryKey,
    queryFn: ({ pageParam = 1 }) =>
      createFetchData({ url, params: { ...params, page: pageParam } }).then(
        json => {
          if (!json?.data?.meta) {
            global.console.warn(
              'useInfiniteCollectionQuery: response should contain `meta.next_page` for pagination'
            );
          }
          return json;
        }
      ),
    getNextPageParam: lastPage => lastPage.data.meta.next_page,
    getPreviousPageParam: firstPage => firstPage.data.meta.prev_page
  });

  const infiniteScrollItems = useMemo(
    () =>
      (query.data?.pages || []).reduce(
        (acc, page) => [...acc, ...getData(page.data)],
        []
      ),
    [query.data?.pages]
  );

  return {
    ...query,
    queryKey,
    infiniteScrollItems
  };
}
