import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import OwnerStorySkeleton from 'dpl/components/OwnerStory/OwnerStorySkeleton';
import { STORIES_PER_PAGE } from 'dpl/containers/BreederProfile/Show/BreederProfileOwnerStoriesContainer';
import { makeSequence } from 'dpl/shared/utils';
import { isGreaterThanBreakpoint } from 'dpl/util/grid';

const IS_GT_MD = isGreaterThanBreakpoint('md');

export default function BreederProfileOwnerStoriesTabSkeleton({ className }) {
  return (
    <div
      className={classnames(
        'BreederProfileOwnerStoriesTabSkeleton container-xxl mb12 mb16-md',
        className
      )}
    >
      <div className="pb8">
        <SkeletonBlock
          className="font-20 md:font-24 mb4"
          height="1em"
          width="10em"
        />
        <SkeletonBlock
          className="font-16 md:font-18"
          height="1em"
          width="32em"
        />
        {!IS_GT_MD && (
          <SkeletonBlock
            className="mt2 font-16 md:font-18"
            height="1em"
            width="12em"
          />
        )}
      </div>
      <ul className="grid lg:grid-cols-2 gap-80">
        {makeSequence(STORIES_PER_PAGE).map(index => (
          <li
            key={index}
            className={classnames({
              'bt b--default pt8 pt12-md': index > (IS_GT_MD ? 1 : 0)
            })}
          >
            <OwnerStorySkeleton />
          </li>
        ))}
      </ul>
    </div>
  );
}

BreederProfileOwnerStoriesTabSkeleton.propTypes = {
  className: PropTypes.string
};

BreederProfileOwnerStoriesTabSkeleton.defaultProps = {
  className: null
};
