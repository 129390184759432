import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isMobileUA, scrollTo, debounce } from 'dpl/shared/utils';
import FixedScrollWrapper from 'dpl/components/FixedScrollWrapper';
import Icon from 'dpl/common/components/Icon';

const isMobile = isMobileUA();

function HorizontalSliderArrow({
  direction,
  onClick,
  arrowClassName,
  iconClassName,
  iconName,
  enabled,
  onAnimationEnd
}) {
  const iconArrowName = `${iconName}-${direction}`;
  return (
    <button
      type="button"
      onClick={() => onClick(direction)}
      className={classnames(
        'HorizontalSliderArrow top-0 bottom-0 transition absolute z-1 child',
        arrowClassName,
        {
          'left-0': direction === 'left',
          'right-0': direction === 'right',
          dn: !enabled
        }
      )}
    >
      <div
        className={`HorizontalSliderArrow__${direction} flex items-center justify-center ph3-lg ph1`}
        onAnimationEnd={onAnimationEnd}
      >
        <Icon
          className={iconClassName}
          height="48px"
          name={iconArrowName}
          width="48px"
        />
      </div>
    </button>
  );
}

HorizontalSliderArrow.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
  onClick: PropTypes.func.isRequired,
  arrowClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  iconName: PropTypes.string,
  enabled: PropTypes.bool.isRequired,
  onAnimationEnd: PropTypes.func
};

HorizontalSliderArrow.defaultProps = {
  arrowClassName: '',
  iconClassName: '',
  iconName: 'fat-arrow',
  onAnimationEnd: null
};

export default class HorizontalSlider extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    arrowClassName: PropTypes.string,
    leftArrowClassName: PropTypes.string,
    rightArrowClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    iconName: PropTypes.string,
    leftIconClassName: PropTypes.string,
    rightIconClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    persistentArrows: PropTypes.bool, // whether to always show arrows if there's more content
    onAnimationEnd: PropTypes.func,
    setScrollContainerRef: PropTypes.func
  };

  static defaultProps = {
    children: null,
    className: '',
    containerClassName: '',
    arrowClassName: 'f7 white text-shadow b',
    leftArrowClassName: '',
    rightArrowClassName: '',
    iconClassName: '',
    iconName: 'fat-arrow',
    leftIconClassName: '',
    rightIconClassName: '',
    persistentArrows: false,
    onAnimationEnd: null,
    setScrollContainerRef: () => {}
  };

  childrenRefs = [];

  state = {
    hasMoreContentToRight: false,
    hasMoreContentToLeft: false
  };

  scrollContainerEl = {};

  handleArrowClick = arrowDirection => {
    const { clientWidth, scrollLeft } = this.scrollContainerEl;

    const firstHiddenChild = this.childrenRefs.find(el =>
      arrowDirection === 'right'
        ? el.offsetLeft + el.offsetWidth > clientWidth + scrollLeft
        : el.offsetLeft + el.offsetWidth > scrollLeft
    );

    let newScrollLeft;

    // this is to compensate when we're in the middle of a child that takes up
    // the entire container's width. in such case we're just gonna scroll the
    // entire container's width
    if (!firstHiddenChild || firstHiddenChild.offsetWidth > clientWidth) {
      newScrollLeft =
        arrowDirection === 'right'
          ? scrollLeft + clientWidth
          : scrollLeft - clientWidth;
    } else {
      newScrollLeft =
        arrowDirection === 'right'
          ? firstHiddenChild.offsetLeft
          : firstHiddenChild.offsetLeft -
            clientWidth +
            firstHiddenChild.offsetWidth;
    }

    scrollTo({
      left: newScrollLeft,
      behavior: 'smooth',
      element: this.scrollContainerEl
    });
  };

  updateArrowsIfNeeded = () => {
    if (!isMobile) {
      const hasMoreContentToLeft = this.hasMoreContent('left');
      const hasMoreContentToRight = this.hasMoreContent('right');

      if (
        hasMoreContentToRight !== this.state.hasMoreContentToRight ||
        hasMoreContentToLeft !== this.state.hasMoreContentToLeft
      ) {
        this.setState({
          hasMoreContentToLeft,
          hasMoreContentToRight
        });
      }
    }
  };

  handleScroll = debounce(this.updateArrowsIfNeeded, 200);

  hasMoreContent(arrowDirection) {
    const { offsetWidth, scrollWidth, scrollLeft } = this.scrollContainerEl;

    let retVal = false;
    if (arrowDirection === 'right') {
      retVal = scrollLeft + offsetWidth < scrollWidth;
    } else {
      retVal = scrollLeft > 0;
    }

    return retVal;
  }

  componentDidMount() {
    if (!isMobile && !CONFIG.isTest) {
      this.scrollContainerEl.addEventListener('scroll', this.handleScroll);
    }

    this.updateArrowsIfNeeded();
  }

  componentWillUnmount() {
    if (!isMobile && !CONFIG.isTest) {
      this.scrollContainerEl.removeEventListener('scroll', this.handleScroll);
    }
  }

  componentDidUpdate() {
    this.updateArrowsIfNeeded();
  }

  render() {
    const {
      arrowClassName,
      children,
      className,
      containerClassName,
      iconClassName,
      iconName,
      leftArrowClassName,
      leftIconClassName,
      persistentArrows,
      rightArrowClassName,
      rightIconClassName,
      onAnimationEnd,
      setScrollContainerRef
    } = this.props;

    const { hasMoreContentToLeft, hasMoreContentToRight } = this.state;

    this.childrenRefs = [];

    return (
      <div
        className={classnames('HorizontalSlider relative', className, {
          'hide-child': !persistentArrows
        })}
      >
        <HorizontalSliderArrow
          direction="left"
          onClick={this.handleArrowClick}
          arrowClassName={classnames(arrowClassName, leftArrowClassName)}
          iconClassName={classnames(iconClassName, leftIconClassName)}
          iconName={iconName}
          enabled={hasMoreContentToLeft}
        />
        <FixedScrollWrapper
          axis="X"
          setRef={el => {
            this.scrollContainerEl = el;
            setScrollContainerRef(el);
          }}
          className={classnames(
            'relative no-scrollbars overflow-y-hidden nowrap',
            containerClassName
          )}
        >
          {Children.map(
            children,
            (child, idx) =>
              child &&
              cloneElement(child, {
                key: idx,
                ref: el => el && this.childrenRefs.push(el)
              })
          )}
        </FixedScrollWrapper>
        <HorizontalSliderArrow
          direction="right"
          onClick={this.handleArrowClick}
          arrowClassName={classnames(arrowClassName, rightArrowClassName)}
          iconClassName={classnames(iconClassName, rightIconClassName)}
          iconName={iconName}
          enabled={hasMoreContentToRight}
          onAnimationEnd={onAnimationEnd}
        />
      </div>
    );
  }
}
