import React from 'react';

import { isGreaterThanBreakpoint } from 'dpl/util/grid';
import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';

const IS_GT_MD = isGreaterThanBreakpoint('md');
const IMAGE_SIZE = IS_GT_MD ? '56px' : '48px';

export default function OwnerStorySkeleton() {
  return (
    <div className="OwnerStorySkeleton">
      <div className="flex items-center gap-12 md:gap-16 mb5">
        <SkeletonBlock
          className="br-100"
          height={IMAGE_SIZE}
          width={IMAGE_SIZE}
        />
        <div>
          <SkeletonBlock className="font-16" height="1em" width="3.5em" />
          <SkeletonBlock
            className="font-14 md:font-16 mt1"
            height="1em"
            width="5em"
          />
        </div>
      </div>
      <div className="flex flex-column gap-4 mt4">
        <SkeletonBlock className="font-16" height="1em" width="100%" />
        {IS_GT_MD && (
          <SkeletonBlock className="font-16" height="1em" width="100%" />
        )}
        <SkeletonBlock className="font-16" height="1em" width="50%" />
      </div>
      <div className="mt4">
        <div
          className="grid grid-cols-2 gap-4 md:gap-16"
          style={{ maxWidth: 420 }}
        >
          <SkeletonBlock
            className="font-14 md:font-18 br2"
            height={IS_GT_MD ? '120px' : '100px'}
          />
          <SkeletonBlock
            className="font-14 md:font-18 br2"
            height={IS_GT_MD ? '120px' : '100px'}
          />
        </div>
      </div>
    </div>
  );
}
