import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  ANALYTICS_EVENTS,
  ANALYTICS_VIEW_FEATURES,
  ANALYTICS_VIEW_FEATURE_LOCATIONS
} from 'dpl/constants/analytics';
import { sendRudderstackEvent } from 'dpl/util/analytics';
import Input from 'dpl/common/design_system/Input';
import Button from 'dpl/common/design_system/Button';

export function NewsletterFormSuccess() {
  return (
    <Fragment>
      <h4 className="font-24 md:font-32 lh-title mb2">
        Thanks for subscribing!
      </h4>
      <p className="font-14 md:font-16 lh-title mb4 mb6-md ph6">
        <span>
          We&apos;ll be in touch soon. In the meantime, be sure to check out our
        </span>
        &nbsp;
        <a
          className="underline"
          href="https://www.instagram.com/gooddog"
          rel="noopener noreferrer"
          target="_blank"
        >
          #GoodDogPack on Instagram
        </a>
        !
      </p>
    </Fragment>
  );
}

export default class NewsletterForm extends Component {
  static propTypes = {
    className: PropTypes.string,
    formState: PropTypes.shape({
      email: PropTypes.string
    }).isRequired,
    handleFormFieldChange: PropTypes.func.isRequired,
    handleFormSubmission: PropTypes.func.isRequired,
    didFormSubmissionSucceed: PropTypes.bool.isRequired
  };

  static defaultProps = {
    className: null
  };

  handleSubmit = e => {
    e.preventDefault();
    sendRudderstackEvent(ANALYTICS_EVENTS.LEAD_CAPTURED, {
      view_feature: ANALYTICS_VIEW_FEATURES.NEWSLETTER_SIGNUP,
      view_feature_location: ANALYTICS_VIEW_FEATURE_LOCATIONS.FOOTER
    });
    this.props.handleFormSubmission();
  };

  render() {
    const {
      className,
      formState,
      handleFormFieldChange,
      didFormSubmissionSucceed
    } = this.props;

    return (
      <div
        className={classnames(
          'NewsletterForm tc stone-dark bg-sky pv10 ph5',
          className
        )}
      >
        {didFormSubmissionSucceed ? (
          <NewsletterFormSuccess />
        ) : (
          <form onSubmit={this.handleSubmit}>
            <h4 className="font-24 md:font-32 lh-title mb2">
              (In)box full of puppies
            </h4>
            <p className="font-14 md:font-16 lh-title mb4 mb6-md ph6">
              Get cute pups, helpful tips, and more sent to your inbox.
            </p>
            <div
              className="inline-flex gap-4 bg-white br3 pv1 ph1 w-100"
              style={{ maxWidth: '546px' }}
            >
              <Input
                className="w-100 b--none"
                name="email"
                onChange={handleFormFieldChange}
                type="email"
                value={formState.email || ''}
              />
              <Button type="submit" variant="primary">
                <span className="font-16 pv1-md ph3 ph4-md">Subscribe</span>
              </Button>
            </div>
          </form>
        )}
      </div>
    );
  }
}
