import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';

import { trackPageView } from 'dpl/util/analytics';

export default function SectionViewTracker({
  name,
  category,
  children,
  threshold,
  triggerOnce
}) {
  const [ref] = useInView({
    triggerOnce,
    threshold,
    onChange: inView => {
      if (!inView) return;
      trackPageView({ name, category });
    }
  });

  return <div ref={ref}>{children}</div>;
}

SectionViewTracker.propTypes = {
  name: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  triggerOnce: PropTypes.bool,
  threshold: PropTypes.number
};

SectionViewTracker.defaultProps = {
  triggerOnce: true,
  threshold: 0.75
};
