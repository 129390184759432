import React from 'react';
import { compose } from 'redux';

import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import { newsletterSignupResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { CONSTRAINT_TYPES } from 'dpl/shared/validations/constraints';
import withFormCapabilities from 'dpl/form/decorators/withFormCapabilities';
import NewsletterForm from 'dpl/components/NewsletterForm';

function NewsletterFormContainer(props) {
  return <NewsletterForm {...props} />;
}

export default compose(
  __DEPRECATED_defineResources(() => ({
    newsletterData: newsletterSignupResourceDefinition()
  })),
  withFormCapabilities(() => ({}), {
    mapPropsAndStateToValidationSchema: (_, { email }) => ({
      email: email ? CONSTRAINT_TYPES.EMAIL : CONSTRAINT_TYPES.TEXT
    }),
    onSubmit: (props, formStateForSubmission) =>
      props.newsletterData.submit({
        user: formStateForSubmission
      })
  })
)(NewsletterFormContainer);
